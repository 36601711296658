@font-face {
	font-family: "Noto-Sans";
	src: url("./assets/fonts/noto-sans/NotoSans-Regular.ttf");
	font-weight: 400;
}
@font-face {
	font-family: "Noto-Sans";
	src: url("./assets/fonts/noto-sans/NotoSans-SemiBold.ttf");
	font-weight: 500;
}
@font-face {
	font-family: "Noto-Sans";
	src: url("./assets/fonts/noto-sans/NotoSans-Bold.ttf");
	font-weight: 600;
}
@font-face {
	font-family: "Noto-Sans";
	src: url("./assets/fonts/noto-sans/NotoSans-ExtraBold.ttf");
	font-weight: 700;
}
